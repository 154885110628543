<template>
   <!-- start::outer div -->
   <div>

	  <apex-line-area-chart :key="keyRefresh" />

	  <!-- start::table container card -->
	  <b-card class="mb-0"
			  no-body>
		 <div class="m-2">
			<b-row>
			   <b-col cols="6">
				  <div class="d-flex align-items-center">
					 <!-- start::column per page -->
					 <template v-if="perPageOptions.length >= 1">
						<b-row class="mr-0">
						   <b-col class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
								  cols="12">

							  <v-select
								  v-model="filters.selectedPageOptions"
								  :clearable="false"
								  :options="perPageOptions"
								  class="per-page-selector d-inline-block mx-50"
								  @input="pageOptionChanged">
								 <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
							  </v-select>
						   </b-col>
						</b-row>
					 </template>
					 <!-- end::column per page -->
					 <table-filters :categories="getSettingExpense"
									:project="$store.getters.getCategoriesProject"
									:expenses="false"
									@selectedCategory="onFilter"
									@selectedProject="onFilterProject"
									@selectedRepeat="onRepeat"></table-filters>
					 <default-filter :order="order" :sort="sort" defaultFilter="Ημερομηνία" @default="defaultFilter"/>
				  </div>
			   </b-col>


			   <!-- start::column search -->
			   <b-col cols="12"
					  md="6">
				  <div class="d-flex align-items-center justify-content-end">
					 <b-form-input v-model="filters.term"
								   aria-placeholder="Αναζήτηση..."
								   class="d-inline-block mr-1" />
					 <b-button
						 v-b-toggle.add-expence
						 class="text-nowrap"
						 variant="primary"
						 @click.prevent="drawerActive = true,expensesById =null,title='Νέο'"
					 >
						<feather-icon class="mr-25"
									  icon="PlusIcon" />
						<span>{{ $t('generic.add') }}</span>
					 </b-button>

				  </div>
			   </b-col>
			   <!-- end::column search -->
			</b-row>
		 </div>

		 <!-- start::table -->
		 <b-table id="contacts-table"
				  :busy="isLoading"
				  :current-page="filters.currentPage"
				  :empty-text="$t('errors.no-entries-found')"
				  :fields="getExpensesesTableColumns"
				  :items="getExpensesess.data"
				  :perPage="0"
				  class="position-relative"
				  no-local-sorting
				  primary-key="id"
				  responsive
				  show-empty
				  :sort-by="getOrderName(this.order)"
				  :sort-desc="this.sort==='desc'"
				  @sort-changed="sortingChanged"
		 >

			<template #cell(title)="data">
			   <div class="d-flex flex-column">
				  <b-link v-b-toggle.add-expence
						  class="mr-1 font-weight-bold d-block text-nowrap"
						  @click="getById(data.item.id)">{{ truncate(data.item.title) }}
				  </b-link>
				  <small v-if="data.item.repeatable"
						 class="text-muted">{{ convertDateTrans(data.item.interval) }}</small>
			   </div>
			</template>
			<template #cell(datestamp)="data">
			   {{ convertDate(data.item.datestamp) }}
			</template>
			<template #cell(paymentMethod)="data">



			   {{ data.item.paymentMethod.charAt(0).toUpperCase() + data.item.paymentMethod.substring(1) }}

			</template>
			<!--			<template #cell(category.title)="data">-->
			<!--			   {{data.item.category.title}}-->
			<!--			</template>-->

			<template #cell(amount)="data">
			   {{
				  '€' + new Intl.NumberFormat('de-DE', {
					 style: 'currency',
					 currency: 'EUR',
					 currencyDisplay: "code"
				  }).format(data.item.amount)
					  .replace("EUR", "")
					  .trim()
			   }}
			</template>


			<template #cell(actions)="data">
			   <div class="text-nowrap">


				  <feather-icon v-b-toggle.add-expence
								class="mr-2"
								icon="EditIcon"
								@click="getById(data.item.id)" />

				  <feather-icon class="cursor-pointer"
								icon="TrashIcon"
								@click=deleteExpenseses(data) />


			   </div>


			</template>
			<!--			&lt;!&ndash; end::column actions &ndash;&gt;-->
		 </b-table>
		 <!-- end::table -->

		 <div class="mx-2 mb-2">
			<b-row>
			   <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start"
					  cols="12"
					  sm="6">
            <span class="text-muted">
              {{ $t('tables.view-page') }} {{ filters.currentPage }} {{
				  $t('tables.from')
			  }} 1
              {{ $t('tables.in-total') }} {{ totalPages }} {{ $t('tables.entries') }}.
            </span>
			   </b-col>

			   <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end"
					  cols="12"
					  sm="6">
				  <b-pagination
					  v-model="filters.currentPage"
					  :per-page="filters.selectedPageOptions"
					  :total-rows="totalPages"
					  aria-controls="contacts-table"
					  class="mb-0 mt-1 mt-sm-0"
					  first-number
					  last-number
					  next-class="next-item"
					  prev-class="prev-item"
				  >
					 <template #prev-text>
						<feather-icon icon="ChevronLeftIcon"
									  size="18" />
					 </template>
					 <template #next-text>
						<feather-icon icon="ChevronRightIcon"
									  size="18" />
					 </template>
				  </b-pagination>
			   </b-col>
			</b-row>
		 </div>
	  </b-card>
	  <!-- end::table container card -->

	  <!-- start::addNew sidebar -->
	  <add-expence :expenses="expensesById"
				   :title="title"
				   @key="changeKey"></add-expence>
	  <!-- end::addNew sidebar -->
   </div>
   <!-- end::outer div -->
</template>

<script>
import {
   BAvatar,
   BButton,
   BCard,
   BCol,
   BDropdown,
   BDropdownItem,
   BFormInput,
   BLink,
   BMedia,
   BPagination,
   BRow,
   BTable,
   BTooltip,
   VBToggle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {mapGetters} from "vuex";
import TableFilters from '@/views/projects/projects-list/TableFilters.vue'
import AddExpence from "@/views/caldo-global/AddExpence";
import ToastMixin from "@/Mixins/ToastMixin";
import _ from "lodash";
import ApexLineAreaChart from '../charts-and-maps/charts/apex-chart/ApexLineAreaChart.vue';
import moment from "moment";
import caldoSettings from "@/caldoSettings";
import DefaultFilter from "@/views/projects/projects-list/DefaultFilter";


export default {
   directives: {
	  "b-toggle": VBToggle,
   },

   components: {
	  DefaultFilter,
	  BCard,
	  BRow,
	  BCol,
	  vSelect,
	  BFormInput,
	  BButton,
	  BTable,
	  BTooltip,
	  BMedia,
	  BAvatar,
	  BLink,
	  BDropdown,
	  BDropdownItem,
	  BPagination,
	  TableFilters,
	  AddExpence,
	  ApexLineAreaChart,
   },
   mixins: [ToastMixin],
   data() {
	  return {
		 graph: [],
		 title: null,
		 keyRefresh: 1,
		 drawerActive: false,
		 expensesById: null,
		 filters: {
			currentPage: 1,
			categoryId: null,
			projectId: null,
			selectedPageOptions: this.$store.getters.getDynamicPreference['expensesListResultsPerPage'],
			repeatable: 'all',
		 },
		 perPageOptions: [5, 10, 25, 50, 100],
		 recordsPerPage: 10,
		 totalPages: 0,
		 isLoading: true,
		 order: '',
		 sort: '',

	  }
   },
   watch: {
	  filters: {
		 deep: true,
		 handler() {

			this.isLoading = true

			this.fetchPages(this)

		 }

	  },
   },
   computed: {
	  ...mapGetters(["getExpensesesTableColumns", "getExpensesess", 'getSettingExpense']),


   },
   methods: {

	  truncate(name, surname = '') {
		 return caldoSettings.truncateString(`${name} ${surname}`)
	  },
	  convertDateTrans(date) {
		 return _.find(caldoSettings.intervalOptions, {value: date}).text
	  },
	  changeKey() {
		 this.keyRefresh = this.keyRefresh + 1;
	  },
	  async pageOptionChanged(value) {
		 this.isLoading = true;
		 this.$store.dispatch('updatePageSelectedOption', value);
		 await this.$store.dispatch("setDynamicPreferences", ['expensesListResultsPerPage', value]);
		 this.selectedPageOptions = value
	  },
	  onFilter(e) {
		 this.filters.categoryId = e?.id ?? null
	  },
	  onFilterProject(e) {
		 this.filters.projectId = e?.id ?? null
		 console.log( this.filters.projectId);
	  },
	  onRepeat(e) {
		 this.filters.repeatable = e ?? null
	  },
	  async getById(id) {
		 this.title = 'Επεξεργασία';
		 await this.$store.dispatch('fetchExpensesesById', id);
		 this.expensesById = this.$store.getters.getByIdExpense;
	  },
	  convertDate(date) {

		 return moment(date).format('DD-MM-yy')
	  },


	  async sortingChanged(value) {

		 console.log(value)
		 let order = {
			"id": 'id',
			"category.title": 'category',
			"company.vat": 'vat',
			'title':'title',
			'datestamp':'datestamp',
			'amount':'amount',
			'paymentMethod':'paymentMethod',
		 }
		 this.order = order[value.sortBy];
		 this.sort = value.sortDesc ? 'desc' : 'asc';
		 await this.$store.dispatch('fetchExpensesesCategories', {
			params: {
			   "page": value.currentPage,
			   "rpp": this.filters.selectedPageOptions,
			   "categoryId": this.filters.categoryId,
			   "projectId": this.filters.projectId,
			   "searchKey": this.filters.term === '' ? null : this.filters.term,
			   "orderBy": [this.order],
			   "sortBy": this.sort
			}
		 });

		 // self.totalPages = self.$store.getters.getExpensesess.total

		 let sortBy;
		 let orderBy;

		 console.log(value);

		 switch (value.sortBy) {
			case 'id':
			   sortBy = 'id'
			   break;
			case 'title':
			   sortBy = 'title'
			   break;
			case 'category.title':
			   sortBy = 'category'
			   break;
			case 'datestamp':
			   sortBy = 'datestamp'
			   break;
			case 'amount':
			   sortBy = 'amount'
			   break;
			case 'paymentMethod':
			   sortBy = 'paymentType'
			   break;
		 }

		 orderBy = value.sortDesc ? 'desc' : 'asc'


		 await this.$store.dispatch("setDynamicPreferences", ['expenses_list_order', `${sortBy}_${orderBy}`]);



	  },
	  async defaultFilter(){

		 await this.$store.dispatch("setDynamicPreferences", ['expenses_list_order', `datestamp_desc`]);
		 this.order = 'datestamp';
		 this.sort = "desc";
		 await this.$store.dispatch("fetchExpensesesCategories", {
			params: {
			   page:1,
			   "rpp": this.filters.selectedPageOptions,
			   "categoryId": this.filters.categoryId,
			   "projectId": this.filters.projectId,
			   "searchKey": this.filters.term === '' ? null : this.filters.term,
			   "orderBy": [this.order],
			   "sortBy": this.sort
			},
		 });



	  },
	  fetchPages: _.debounce(async (self) => {
		 self.isLoading = true;
		 await self.$store.dispatch('fetchExpensesesCategories', self.filtersHandler());
		 //
		 self.totalPages = self.$store.getters.getExpensesess.total
		 self.isLoading = false
	  }, 100),


	  async deleteExpenseses(payload) {
		 const {isConfirmed} = await this.swal()
		 if (isConfirmed) {
			await this.$store.dispatch("deleteExpensesesCategory", payload);
			this.notify('Διαγράφηκε', `danger`)
			this.changeKey();
		 }


	  },
	  filtersHandler() {
		 return {
			params: {
			   "page": this.filters.currentPage,
			   "rpp": this.filters.selectedPageOptions,
			   "repeatable": this.filters.repeatable,
			   "categoryId": this.filters.categoryId,
			   "projectId": this.filters.projectId,
			   "searchKey": this.filters.term === '' ? null : this.filters.term,
			   "orderBy": [this.order],
			   "sortBy": this.sort
			}
		 }
	  },

	  async getOrder() {
		 await this.$store.dispatch('fetchDynamicPreferences')
		 const order = await this.$store.getters.getDynamicPreference['expenses_list_order'].split("_")

		 

		 //
		 this.order = order[0]=='paymentType'?'paymentMethod':order[0]
		 this.sort = order[1];
	  },
	  getOrderName(value) {






		 switch (value) {
			case 'id':
			   return 'id'
			case 'title':
			   return 'title'
			case 'category':
			   return 'category.title'
			case 'datestamp':
			   return 'datestamp'
			case 'amount':
			   return 'amount'
			case 'paymentMethod':
			   return 'paymentType'

		 }

	  }


   },
   async mounted() {
	  await this.getOrder()
	  this.fetchPages(this);


	  await this.$store.dispatch('fetchExpenseSetting');
   },
   async created() {
	  this.isLoading = true;
	  await this.getOrder()
	  this.filters.selectedPageOptions = await this.$store.getters.getDynamicPreference['expensesListResultsPerPage']
	  this.isLoading = false;
   }
}
;
</script>

<style lang="scss"
	   scoped>
.per-page-selector {
   width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/chart-apex.scss';


.media-aside {
   top: 4px;
   position: relative;
}
</style>