<template>
   <b-card no-body>
	  <b-card-header class="pb-0">
		 <!-- title and subtitle -->
		 <span ref="monthRef"
			   class="text-primary ">{{ getCurrentMonth }}</span>
		 <!--/ title and subtitle -->

		 <!-- datepicker -->
		 <v-select
			 v-model="currentMonth"
			 :clearable="false"
			 :options="getMonths"
			 dir="auto"
			 label="text"
			 placehoder="Επιλέξτε..."
			 style="width: 150px"
			 @input="(val) => selectedMonth(val)">


		 </v-select>
	  </b-card-header>

	  <vue-apex-charts
		  ref="realtimeChart"
		  :options="apexChatData.lineAreaChartSpline.chartOptions"
		  :series="apexChatData.lineAreaChartSpline.series"
		  height="250"
		  type="area"
	  />
   </b-card>
</template>

<script>
import {BCard, BCardBody, BCardHeader, BCardSubTitle, BCardTitle, BDropdown, BDropdownItem,} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import apexChatData from "./apexChartData";
import _ from "lodash";
import axiosIns from "@/libs/axios";
import caldoSettings from "@/caldoSettings";
import vSelect from "vue-select";
import moment from "moment";
import {codeListGroupHorizontal} from "@/views/components/list-group/code";

export default {
   components: {
	  BCard,
	  VueApexCharts,
	  BCardHeader,
	  BCardBody,
	  BCardTitle,
	  BCardSubTitle,
	  vSelect,
	  BDropdown,
	  BDropdownItem,
	  flatPickr,
   },

   data() {
	  return {
		 currentMonth: null,
		 apexChatData,
		 graph: [],
		 rangePicker: ["2019-05-01", "2019-05-10"],
	  };
   },
   computed: {
	  getCurrentMonth() {
		 let result = _.find(caldoSettings.months, function (n) {
			if (moment().month() + 1 == n.value) {
			   return n
			}
		 });
		 return `${result.text} ${moment().year()}`;
	  },
	  getMonths() {
		 let months = []
		 _.mapValues(caldoSettings.months, (v, i) => {
			if (moment().month() == parseInt(i)) {
			   this.selectedMonth(v)
			}
			if (moment().month() >= parseInt(i)) {
			   months.push(v)
			}
		 });
		 return months
	  }
   },
   methods: {
	  async selectedMonth(month) {
		 this.currentMonth = month

		 if (this.$refs.monthRef)
			this.$refs.monthRef.innerText = `${month.text} ${moment().year()}`
		 await this.initGraph(month.value);
	  },
	  async initGraph(month = moment().month() + 1) {
		 const response = await axiosIns.post(`/expense-graph`, {
			"date": `${moment().year()}-${month}-01`
		 })




		 let dates;


		 let arr = _.mapValues(response.data, (key, value) => {


			let index = Object.keys(key).sort()

			let sortedArrayOfObject = index.map((v) => {
			   return key[v]
			})

			dates = _.keys(key).sort()

			return {
			   'name': value,
			   data: sortedArrayOfObject
			}
		 })

		 this.graph = _.toArray(arr);


		 this.$refs.realtimeChart.updateSeries(this.graph);
		 this.$refs.realtimeChart.updateOptions({
			xaxis: {
			   categories: dates,
			},
		 });
	  }
   },

   async created() {
	  await this.initGraph();
   },
};
</script>
